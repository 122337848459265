import React, { useState, useEffect } from 'react';
import { useSearchParams   } from 'react-router-dom';
import { InnerPageBanner } from 'src/components/common/Widgets';
import { Checked, GroupImage } from 'src/assets';
import emailjs from 'emailjs-com';
import { Button, Typography } from 'src/components/common/Base';
import './style.scss';

const JobPostDetails = () => {
  const backgroundImageUrl = GroupImage;
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  // const { id } = location.state || {};
  // debugger; // eslint-disable-line no-debugger
  const [job, setJob] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

useEffect(() => {
  const fetchJobDetails = async () => {
    try {
      const response = await fetch(`https://geniuslogix.com/api/jobs/getAJob/${id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch job details');
      }
      const data = await response.json();
      setJob(data);
    } catch (error) {
      console.error('Error fetching job details:', error);
      // Handle error here (e.g., set state to indicate fetching failed)
    }
  };
  fetchJobDetails();
}, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      console.log(formData);
      setIsSubmitted(true);
      emailjs.sendForm('service_gnoxn8v', 'template_po1a7f4', e.target, 'jPAZ-WSmDjXn96NUr')
        .then(() => {
          setFormData({ name: '', email: '', message: '' });
        }, (error) => {
          console.error('Error sending email:', error.text);
          alert('Error sending message. Please try again later.');
        });
    } else {
      setErrors(errors);
    }
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.name.trim()) {
      errors.name = 'Name is required';
    }
    if (!data.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Email address is invalid';
    }
    if (!data.message.trim()) {
      errors.message = 'Message is required';
    }
    return errors;
  };

  if (!job) {
    return null; // You can render a loading spinner or other placeholder
  }
  return (
    <>
      <InnerPageBanner backgroundImage={backgroundImageUrl} title={job.title} />
      <section className='post-details-page ah-py-100'>
        <div className='ah-container'>
          <div className='row'>
            <div className='col-md-8 left non-sticky'>
              <figure>
                <img className='img-fluid' src={job.thumbnail} alt="Main" />
              </figure>
              <h3>{job.title}</h3>
              <p>{job.description}</p>
              <h4>Job Summary:</h4>
              <p>{job.summaryDescription}</p>
            </div>
            {/* Queries form */}
            <div className='col-md-4 right sticky'>
              <div className='queries-form bg-theme'>
                <ul className='about-job-info'>
                  <li><span>Experience : <span>{job.experience}</span></span> </li>
                  <li><span>No of Vacancies : <span>{job.noOfVacancies}</span></span> </li>
                  <li><span>Working Hours : <span>{job.workingHours}</span></span> </li>
                  <li><span>Working Days : <span>{job.workingDays}</span></span> </li>
                  <li><span>Salary : <span>{job.salary}</span></span> </li>
                  <li><span>Deadline : <span>{job.deadline}</span></span> </li>
                </ul>
                <div>
                  {!isSubmitted ? (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className='form-group'>
                            <label htmlFor="name">Name<sup>*</sup>:</label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                            />
                            {errors.name && <span>{errors.name}</span>}
                          </div>
                          <div className='form-group'>
                            <label htmlFor="email">Email<sup>*</sup>:</label>
                            <input
                              type="email"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                            {errors.email && <span>{errors.email}</span>}
                          </div>
                          <div className='form-group'>
                            <label htmlFor="message">Message<sup>*</sup>:</label>
                            <textarea
                              id="message"
                              name="message"
                              value={formData.message}
                              onChange={handleChange}
                            />
                            {errors.message && <span>{errors.message}</span>}
                          </div>
                          <h4>Responsibilities:</h4>
                          <ul className='ah-list-items'>
                            {job.responsibilitiesListItem.map((responsibility, index) => (
                              <li key={index} className='ah-list-item'>
                                <div className='ah-list-text'>{responsibility.listItems}</div>
                              </li>
                            ))}
                          </ul>
                          <h4>Skills:</h4>
                          <ul className='ah-list-items'>
                            {job.skillsDescriptionList.map((skill, index) => (
                              <li key={index} className='ah-list-item'>
                                <div className='ah-list-text'>{skill.listItems}</div>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="col-md-12 mt-3">
                          <div className="form-group mb-0">
                            <Button className={`btn primary desktop w-100 animate__fadeInUp animate__animated animate__slow`} variant={'solid'}>
                              <Typography variant="button-text">Let's Talk</Typography>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <div className='text-center'>
                      <img className={`desktop animate__fadeInUp animate__animated animate__slow`} style={{ width: '200px', height: 'auto' }} src={Checked} alt="Checked" />
                      <h3 className='text-white'>Thank you for your query <span>submission!</span></h3>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobPostDetails;